import "./style.scss";

import React from "react";

export let DocsSectionComponents = {
    SubSection: (props) => {
        return (
            <div id={props.id} className="subsection">
                <h2>{props.header}</h2>
                <div className="subcontent">
                    {props.children}
                </div>
            </div>
        )
    },
    Section: (props) => {
        return (
            <div className="section">
                <h1 className="section-header">{props.header}</h1>
                {props.children}
            </div>
        )
    }
}