import "./style.scss";

import { useState } from "react";
import { Helmet } from "react-helmet";

import { SidebarComponents } from "../../Components/Bars/Sidebar/component"
import { Content } from "../../Components/Containers/Content/component";

let { SidebarItem, SidebarMenu, Sidebar } = SidebarComponents;

export default function Index() {
    let navState = useState(true);

    return (
        <>
            <Helmet>
                <title>Peko Docs</title>
                <meta property="og:title" content="Peko Docs" />
                <meta property="og:description" content="Learn about everything Peko, from the language to modules" />
                <meta name="description" content="Learn about everything Peko, from the language to modules" />
                <meta property="og:url" content="https://docs.pekoui.com" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Sidebar navstate={navState} title="Peko Docs">
                <SidebarItem navstate={navState} label="Language Reference" to="/langref"></SidebarItem>
                <SidebarItem navstate={navState} label="PekoUI | UI Dev" to="/pekoui"></SidebarItem>
                <SidebarMenu navstate={navState} title="Built-in Libraries">
                    <a href="/library/std">Standard Library</a>
                    <a href="/library/runtime">Runtime Library</a>
                    <a href="/library/console">Console Library</a>
                    <a href="/library/json">JSON Library</a>
                    <a href="/library/lexer">Lexer Library</a>
                    <a href="/library/threads">Threads Library</a>
                    <a href="/library/sockets">Sockets Library</a>
                    <a href="/library/mysql">MySQL Library</a>
                    <a href="/library/pekodb">PekoDB Library</a>
                    <a href="/library/crypto">Crypto Library</a>
                    <a href="/library/pekox">PekoXML Library</a>
                </SidebarMenu>
            </Sidebar>
            <Content leftMargin={50}>
                <div className="docs-header">
                    <h1>Welcome to the Peko Docs</h1>
                    <span>Here, you can learn about Pekoscript, learn about its many built in libraries, or follow dedicated tutorials on using Peko for UI Development.</span>
                </div>
                <div className="quick-refs">
                    <div className="refs-container">
                        <a href="/langref" className="quick-ref">
                            <h1>Language Reference</h1>
                            <span>Learn all about the Pekoscript language and its powerful features in depth.</span>
                        </a>
                        <a href="/pekoui" className="quick-ref">
                            <h1>UI Development</h1>
                            <span>Learn all about how UI development in Peko works, and follow a tutorial to create a simple app.</span>
                        </a>
                        <a href="/library/std" className="quick-ref">
                            <h1>Standard Library</h1>
                            <span>Learn about the different components of the Peko standard library, and find examples on how to use them.</span>
                        </a>
                        <a href="/library/console" className="quick-ref">
                            <h1>Console Library</h1>
                            <span>Learn about the Peko console library and how to use it to interact with output and input.</span>
                        </a>
                    </div>
                </div>
            </Content>
        </>
    )
}