import { marked } from "marked";
import "./style.scss";
import { useEffect } from "react";
import hljs from "highlight.js";
import { useLocation } from 'react-router-dom'


/*
*/
export let ModuleDisplay = (props) => {
    const convertArrayToSource = (array) => {
        let source = "```typescript";
        source += `
`;
        
        for(let i = 0; i < array.length; i++) {
            source += "  ";
            source += array[i];
            source += `
`;
        }

        source += "```";

        console.log(source)

        return source;
    };

    useEffect(() => {
        hljs.highlightAll();
    })

    const location = useLocation();
    
    return (
        <div className="module-display">
            <div className="docs-sidebar">
                <div className="header">
                    <span className="title">{props.title}</span>
                    <span className="name">{props.name}</span>
                </div>
                {
                    (props.json.modules.length > 0 || props.parent_module) ?
                        <ul className="child-modules styled-nav">
                            <span className="header">Modules</span>
                            {(props.parent_module) ?
                            <li className="child">
                                <a href={props.parent_module.path}>
                                    <span className="title">Parent Module</span>
                                    <span className="name">{props.parent_module.name}</span>
                                </a>
                            </li> : ""}
                            {props.json.modules.map(module_json => 
                                <li className="child">
                                    <a href={`${location.pathname}/${module_json.name}`}>
                                        <span className="title">{module_json.title}</span>
                                        <span className="name">{module_json.name}</span>
                                    </a>
                                </li>
                            )}
                        </ul> : ""
                }
                {
                    (props.json.variables.length > 0) ?
                        <ul className="functions styled-nav">
                            <span className="header">Variables</span>
                            {props.json.variables.map(variable_json => 
                                <li className="child">
                                    <a href={`${location.pathname}/#variable-${variable_json.name}`}>
                                        <span className="title">{variable_json.name}</span>
                                    </a>
                                </li>
                            )}
                        </ul> : ""
                }
                {
                    (props.json.functions.length > 0) ?
                        <ul className="functions styled-nav">
                            <span className="header">Functions</span>
                            {props.json.functions.map(function_json => 
                                <li className="child">
                                    <a href={`${location.pathname}/#function-${function_json.name}`}>
                                        <span className="title">{function_json.name}</span>
                                    </a>
                                </li>
                            )}
                        </ul> : ""
                }
                {
                    (props.json.classes.length > 0) ?
                        <ul className="functions styled-nav">
                            <span className="header">Classes</span>
                            {props.json.classes.map(class_json => 
                                <li className="child">
                                    <a href={`${location.pathname}/#class-${class_json.name}`}>
                                        <span className="title">{class_json.name}</span>
                                    </a>
                                </li>
                            )}
                        </ul>: ""
                }
            </div>
            <div className="module-content">
                <div className="symbol-section">
                    <span className="header">Overview</span>
                    <div className="symbol-definition">
                        <div className="symbol-header">
                            <span className="symbol">{props.title}</span>
                            <span className="source">{props.name}</span>
                        </div>
                        <span className="description">{props.json.overview.description}</span>
                        {
                            (props.json.overview.example.code) ?
                            <div className="example">
                                <span className="example-header">Example</span>
                                <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(convertArrayToSource(props.json.overview.example.code))}}></code></pre>
                            </div>
                            : ""
                        }
                    </div>
                </div>
                {
                    (props.json.variables.length > 0) ?
                        <div className="symbol-section">
                            <span className="header">Variables</span>
                            {props.json.variables.map(variable_json =>
                                <div className="symbol-definition" id={`variable-${variable_json.name}`}>
                                    <div className="symbol-header">
                                        <span className="symbol">{variable_json.name}</span>
                                        <span className="source">{variable_json.source}</span>
                                    </div>
                                    <span className="description">{variable_json.description}</span>
                                    {
                                        (variable_json.example.length > 0) ?
                                        <div className="example">
                                            <span className="example-header">Example{(variable_json.example.length > 1) ? "s" : ""}</span>
                                            {variable_json.example.map(example => 
                                                (example.code) ? <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(convertArrayToSource(example.code))}}></code></pre>
                                                : <span>{example.text}</span>
                                            )}
                                        </div>
                                        : ""
                                    }
                                </div>
                            )}
                        </div> : ""
                }
                {
                    (props.json.functions.length > 0) ?
                        <div className="symbol-section">
                            <span className="header">Functions</span>
                            {props.json.functions.map(function_json =>
                                <div className="symbol-definition" id={`function-${function_json.name}`}>
                                    <div className="symbol-header">
                                        <span className="symbol">{function_json.name}</span>
                                        {function_json.source.map(source => <span className="source">{source}</span>)}
                                    </div>
                                    <span className="description">{function_json.description}</span>
                                    {
                                        (function_json.example.length > 0) ?
                                        <div className="example">
                                            <span className="example-header">Example{(function_json.example.length > 1) ? "s" : ""}</span>
                                            {function_json.example.map(example => 
                                                (example.code) ? <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(convertArrayToSource(example.code))}}></code></pre>
                                                : <span>{example.text}</span>
                                            )}
                                        </div>
                                        : ""
                                    }
                                </div>
                            )}
                        </div> : ""
                }
                {
                    (props.json.classes.length > 0) ?
                        <div className="symbol-section">
                            <span className="header">Classes</span>
                            {props.json.classes.map(class_json =>
                                <div className="symbol-definition" id={`class-${class_json.name}`}>
                                    <div className="symbol-header">
                                        <span className="symbol">{class_json.name}</span>
                                        <span className="source">{class_json.source}</span>
                                    </div>
                                    <span className="description">{class_json.description}</span>
                                    {
                                        (class_json.example.length > 0) ?
                                        <div className="example">
                                            <span className="example-header">Example{(class_json.example.length > 1) ? "s" : ""}</span>
                                            {class_json.example.map(example => 
                                                (example.code) ? <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(convertArrayToSource(example.code))}}></code></pre>
                                                : <span>{example.text}</span>
                                            )}
                                        </div>
                                        : ""
                                    }
                                    <div className="class-methods">
                                        <span className="methods-header">Methods</span>
                                        {class_json.methods.map(method => 
                                            <div className="method">
                                                <div className="method-header">
                                                    <span className="method-name">{method.name}</span>
                                                    {method.source.map(source => <span className="method-source">{source}</span>)}
                                                </div>
                                                <span className="method-description">{method.description}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>: ""
                }
            </div>
        </div>
    );
}