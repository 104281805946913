import "./style.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

export let Note = (props) => {
    return (<div className="note">
        <FontAwesomeIcon icon={faLightbulb} id="light-bulb" />
        <span className="note-message">{props.children}</span>
    </div>);
}