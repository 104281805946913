import "./style.scss";

import React, { Children, useEffect, useState } from "react";

// <Sidebar title="..."><SidebarMenu title="..."><a href="...">...</a></SidebarMenu></Sidebar>
export let SidebarComponents = {
    SidebarItem: (props) => {
        return (<a className="item-link" href={props.to}>
            <li className="item">
                <span className="title">{props.label}</span>
                <div className="arrow"></div>
            </li>
        </a>);
    },
    SidebarMenu: (props) => {
        const [sidebarClosed, setSidebarClosed] = props.navstate;
        const [active, setActive] = useState(false);
        const [menuActive, setMenuActive] = useState(false);

        const openDropdown = (event) => {
            if (sidebarClosed) {
                return;
            }

            let currentParent = event.target.parentElement;
            while (!currentParent.classList.contains("page")) {
                currentParent = currentParent.parentElement;
            }

            const dropdown = currentParent.getElementsByClassName("dropdown-menu")[0];
            setActive(!active);
            setMenuActive(!menuActive);

            if (!dropdown.classList.contains("active")) {
                const dropdowns = document.getElementsByClassName("dropdown-menu");
                for (let i = 0; i < dropdowns.length; i++) {
                    let current_item = dropdowns.item(i);

                    if (current_item.classList.contains("active")) {
                        current_item.classList.remove("active");
                        current_item.setAttribute("style", "height: 0px;");
                        current_item.parentElement.getElementsByClassName('dropdown-toggle')[0].classList.remove("active");
                    }
                }

                dropdown.setAttribute("style", `height: ${dropdown.getAttribute("content-height")}px;`)
            } else {
                dropdown.setAttribute("style", `height: 0px;`)
            }

        }

        const clickedLink = (event) => {
            let currentParent = event.target.parentElement;
            while (!currentParent.classList.contains("page")) {
                currentParent = currentParent.parentElement;
            }

            setActive(!active);
            setMenuActive(!menuActive);

            setSidebarClosed(true);
        }

        return (
            <li className="page">
                <div className={`dropdown-toggle ${(active) ? "active" : ""}`} onClick={openDropdown}>
                    <span className="label">{props.title}</span>
                    <div className="arrow">
                        <div></div><div></div>
                    </div>
                </div>
                <ul className={`dropdown-menu ${(menuActive) ? "active" : ""}`}>
                    {Children.map(props.children, link => <li onClick={clickedLink}>{link}</li>)}
                </ul>
            </li>
        );
    },
    Sidebar: (props) => {
        const [active, setActive] = props.navstate;

        useEffect(() => {
            const dropdowns = document.getElementsByClassName("dropdown-menu");

            for (var i = 0; i < dropdowns.length; i++) {
                dropdowns.item(i).setAttribute("content-height", dropdowns.item(i).scrollHeight)
            }

            for (let i = 0; i < dropdowns.length; i++) {
                dropdowns.item(i).setAttribute("style", "height: 0px;")
            }

            const pages = document.getElementsByClassName("page");

            if (active) {
                for (let i = 0; i < pages.length; i++) {
                    pages.item(i).setAttribute("style", "opacity: 0;")
                }

                for (let i = 0; i < dropdowns.length; i++) {
                    let current_item = dropdowns.item(i);
                    if (current_item.classList.contains("active")) {
                        current_item.classList.remove("active");
                        current_item.setAttribute("style", "height: 0px;")
                        current_item.parentElement.getElementsByClassName('dropdown-toggle')[0].classList.remove("active")
                    }
                }
            }
        })

        let toggleActive = () => {
            setActive(!active);

            const pages = document.querySelectorAll(".item, .page");
            const dropdowns = document.getElementsByClassName("dropdown-menu");

            if (!active) {
                for (let i = 0; i < pages.length; i++) {
                    pages.item(i).setAttribute("style", "opacity: 0;")
                }

                for (let i = 0; i < dropdowns.length; i++) {
                    let current_item = dropdowns.item(i);
                    if (current_item.classList.contains("active")) {
                        current_item.classList.remove("active");
                        current_item.setAttribute("style", "height: 0px;")
                        console.log(current_item.parentElement)
                        current_item.parentElement.getElementsByClassName('dropdown-toggle')[0].classList.remove("active")
                    }
                }
            } else {
                let i = 0;
                var interval_id;

                interval_id = setInterval(() => {
                    if (i < pages.length && active) {
                        pages.item(i).setAttribute("style", "opacity: 1;")
                        i += 1;
                    } else {
                        clearInterval(interval_id);
                    }
                }, 70);
            }
        }

        return (
            <div className={`sidebar-nav ${(active) ? "active" : ""}`}>
                <div className="header">
                    <div className="align">
                        <span className="title">{props.title}</span>
                    </div>
                    <div className="align">
                        <button className={`collapse-sidebar ${(active) ? "active" : ""}`} onClick={toggleActive}><div></div><div></div><div></div></button>
                    </div>
                </div>

                <ul className="pages">
                    {props.children}
                </ul>

                {
                    (props.footer) ?
                        (<a className="footer-link" href="/">
                            <div className="footer">
                                <div className="back">
                                    <div className="arrow"></div>
                                    <span className="label">Docs</span>
                                </div>
                            </div>
                        </a>)
                    : ""
                }
            </div>
        )
    }
    // Sidebar: (props) => {
    //     (
    //         <div className="sidebar-nav active">
    //             <div className="header">
    //                 <span className="title">{props.title}</span>
    //             </div>

    //             <ul className="pages">
    //                 {props.children}
    //             </ul>

    //             <a className="footer-link" href="/">
    //                 <div className="footer">
    //                     <div className="back">
    //                         <div className="arrow"></div>
    //                         <span class="label">Docs</span>
    //                     </div>
    //                 </div>
    //             </a>
    //         </div>
    //     )
    // }
}