import "./style.scss";

import React from "react";

export let ReferenceTableComponents = {
    TableItem: (props) => {
        return (
            <li>
                <span>{props.symbol}</span>
                <div className="arrow"></div>
                <span>{props.definition}</span>
            </li>
        )
    },
    Table: (props) => {
        return (
            <ul className="reference-table">
                {props.children}
            </ul>
        )
    }
}