import { useState } from "react";
import { Helmet } from "react-helmet";

import { SidebarComponents } from "../../Components/Bars/Sidebar/component"
import { DocsSectionComponents } from "../../Components/Containers/DocSection/component";
import { Content } from "../../Components/Containers/Content/component";
import { marked } from "marked";

let { SidebarMenu, Sidebar } = SidebarComponents;
let { Section, SubSection } = DocsSectionComponents;

export default function LibraryDocs() {
    let navState = useState(true);

    return (
        <>
            <Helmet>
                <title>Peko Docs</title>
                <meta property="og:title" content="Peko Docs" />
                <meta property="og:description" content="Learn about everything Peko, from the language to modules" />
                <meta name="description" content="Learn about everything Peko, from the language to modules" />
                <meta property="og:url" content="https://docs.pekoui.com" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Sidebar navstate={navState} title="PekoUI | UI dev" footer>
                <SidebarMenu navstate={navState} title="App setup and creation">
                    <a href="/pekoui#app-setup-project-creation">Creating the project</a>
                    <a href="/langref#app-setup-app-creation">Instantiating the app in Pekoscript</a>
                    <a href="/langref#app-setup-app-layout">Creating an app layout</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Creating pages">
                    <a href="/pekoui#page-creation-structuring">General structuring for pages</a>
                    <a href="/langref#page-creation-logic">Creating page frame and logic</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Putting it all together">
                    <a href="/pekoui#all-together-page-routing">Routing pages in the app</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Example | counter app">
                    <a href="/pekoui#counter-app-setup">Setting up the app</a>
                    <a href="/pekoui#counter-app-page-setup">Creating the counter page</a>
                </SidebarMenu>
            </Sidebar>
            <Content padding={70} leftMargin={0}>
                <Section header="App setup and creation">
                    <SubSection id="app-setup-project-creation" header="Creating the project">
                        <span>The first step to creating a Peko app is using the Peko command line to create a new project.</span>
                        <span>This can be done by doing the following:</span>
                        <pre><code className="language-bash" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`bash
    peko new [project name]
\`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection id="app-setup-app-creation" header="Instantiating the app in Pekoscript">
                        <span>All the instantiation of the App should be done within the OnStart function.</span>
                        <span>To begin, do the following:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    fn OnStart() {
        app := ui::UI(
            title="Hello World", 
            width=800, 
            height=800
        ) // any of these paramaters can be omitted
        
        app.run();
    }
\`\`\`
`)}}></code></pre>
                        <span>In the next section, we'll go over creating pages for this app</span>
                    </SubSection>
                    <SubSection id="app-setup-app-layout" header="Creating an app layout">
                        <span>In Peko, an App layout can be used to specify global structuring and styling for the app.</span>
                        <span>To begin, your project should have a file [project directory]/Layout/Layout.peko and a file [project directory]/Layout/LayoutStyle.scss. If these files do not exist or don't exist at these location, create/move them here.</span>
                        <span>This is what the content of the Layout.peko file should look like:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    style LayoutStyle;

    fn Layout(content: ui::Element) => ui::Element {
        return ...
    }
\`\`\`
`)}}></code></pre>
                        <span>Important things to note here are that the LayoutStyle is imported here, and also that the argument content to the layout function will contain whatever the current page of the app renders.</span>
                        <span>The next step is to tell the app that this is your layout. We'll do this in main.peko where we created the app.</span>
                        <span>Firstly, you need to import the layout, then you can tell the app about it with the set_layout method</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    import { Layout, LayoutStyle } from Layout::Layout; // import both the Layout and its style

    fn OnStart() {
        app := ui::UI(
            title="Hello World", 
            width=800, 
            height=800
        )

        app.set_layout(Layout, LayoutStyle); // this sets the layout
        
        app.run();
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                </Section>
                <Section header="Creating pages">
                    <SubSection id="page-creation-structuring" header="General structuring for pages">
                        <span>Creating Pages in a Peko app is simple, although there is a general style which should be followed</span>
                        <span>To write the code for a page, Create a new folder under your [project directory]/Pages which is named after the page, ensuring that the name of this folder can be a valid Pekoscript identifier.</span>
                        <span>For example, to create a page titled Hello World, a new folder [project directory]/Pages/HelloWorld should be created</span>
                        <span>Next, this folder should contain two files, styling.scss for the page styling and page.peko to hold the page code</span>
                    </SubSection>
                    <SubSection id="page-creation-logic" header="Creating page frame and logic">
                        <span>This section will go over what the pages page.peko should contain, with page.peko being a part of the file structure described in the previous subsection</span>
                        <span>The first line of page.peko should be importing the page's style:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`bash
    style styling;
\`\`\`
`)}}></code></pre>
                        <span>Next, the page should be created as a class which inherits from ui::Page. The name of this class should be the same as whatever the parent folder of page.peko is (i.e., if the parent folder is [project directory]/HelloWorld, then the class should be named HelloWorld)</span>
                        <span>At its bare minimum, this page should implmement ui::Page's render method and its constructor. When implementing the constructor, ensure you call the super constructor and provide the styling</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`bash
    style styling;
    
    class [PageName] from ui::Page {
        constructor() => super(styling) {
            ...
        }

        fn render() => ui::Element {
            <h1>Hello World</h1>
        }
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                </Section>
                <Section header="Putting it all together">
                    <SubSection id="all-together-page-routing" header="Routing pages in the app">
                        <span>Now that we are able to create an app and create pages, we are going to put it together and route the pages to specific url routes of the app</span>
                        <span>This is extremely simple, and only requires two things: 1) The page's code is imported into the main.peko file containing the app, and 2) you call the add_page method of your app to add the page</span>
                        <span>It looks like this:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    import { Layout, LayoutStyle } from Layout::Layout;
    import { Index } from pages::Index::page; // import the page from the pages folder
    
    fn OnStart() {
        app := ui::UI(
            title="Hello World", 
            width=800, 
            height=800
        ) 

        app.set_layout(Layout, LayoutStyle);
        app.add_page("/", Index()) // Routes the page code for the page Index to the url route /
        
        app.run();
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                </Section>
                <Section header="Example | Creating a counter app">
                    <SubSection id="counter-app-setup" header="Setting up the app">
                        <span>Lets create the project first:</span>
                        <pre><code className="language-bash" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`bash
    peko new CounterApp
\`\`\`
`)}}></code></pre>
                        <span>Next lets create the app boilerplate in CounterApp/main.peko:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    import { Layout, LayoutStyle, } from Layout::Layout;
    import { Index } from pages::Index::page;

    fn OnStart() {
        app := ui::UI(
            title="Counter",
            width=500,
            height=500 
        );

        app.set_layout(Layout, LayoutStyle);
        app.add_page("/", Index());
        
        app.run();
    }
\`\`\`
`)}}></code></pre>
                        <span>In the next subsection, we'll create the counter page</span>
                    </SubSection>
                    <SubSection id="counter-app-page-setup" header="Creating the counter page">
                        <span>Lets setup our index page in CounterApp/Pages/Index/page.peko:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    style styling;

    class Index from ui::Page {
        constructor() => super(style) {
        }

        fn render() => ui::Element {
            
        }
    }
\`\`\`
`)}}></code></pre>
                        <span>Next, we're going to add state to the Index page so we can track the counter, and then we're going to initialize it:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    style styling;

    class Index from ui::Page {
        [state] counter: int;

        constructor() => super(style) {
            counter = 0;
        }

        fn render() => ui::Element {
            
        }
    }
\`\`\`
`)}}></code></pre>
                        <span>Now that we've create the page and its state, lets create its xml frame and logic. We'll start by creating a container that returns a header displaying the counter:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    style styling;

    class Index from ui::Page {
        [state] counter: int;

        constructor() => super(style) {
            counter = 0;
        }

        fn render() => ui::Element {
            return <div>
                <h1>\${counter}</div>
            </div>
        }
    }
\`\`\`
`)}}></code></pre>
                        <span>Lastly, lets add an increment button to the counter:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    style styling;

    class Index from ui::Page {
        [state] counter: int;

        constructor() => super(style) {
            counter = 0;
        }

        fn render() => ui::Element {
            return <div>
                <h1>\${counter}</div>
                <button onclick={counter += 1}>increment</button>
            </div>
        }
    }
\`\`\`
`)}}></code></pre>
                        <span>And with that, our app is done. We can now build it, and find the app for our platform the CounterApp/build directory:</span>
                        <pre><code className="language-bash" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`bash
    peko build
\`\`\`
`)}}></code></pre>
                    </SubSection>
                </Section>
            </Content>
        </>
    )
}