import { useState } from "react";
import { Helmet } from "react-helmet";

import { SidebarComponents } from "../../Components/Bars/Sidebar/component"
import { DocsSectionComponents } from "../../Components/Containers/DocSection/component";
import { Content } from "../../Components/Containers/Content/component";

import { marked } from "marked";
import { ReferenceTableComponents } from "../../Components/ReferenceTable/component";
import { Note } from "../../Components/Containers/Note/component";

let { SidebarMenu, Sidebar } = SidebarComponents;
let { Section, SubSection } = DocsSectionComponents;
let { Table, TableItem } = ReferenceTableComponents;

export default function LanguageReference() {
    let navState = useState(true);

    return (
        <>
            <Helmet>
                <title>Peko Docs - Language Reference</title>
                <meta property="og:title" content="Peko Docs - Language Reference" />
                <meta property="og:description" content="Learn about all the components of the Pekoscript language" />
                <meta name="description" content="Learn about all the components of the Pekoscript language" />
                <meta property="og:url" content="https://docs.pekoui.com/langref" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Sidebar navstate={navState} title="Language Reference" footer>
                <SidebarMenu navstate={navState} title="Quick Start">
                    <a href="/langref#quickstart-hello-world">Hello World</a>
                    <a href="/langref#quickstart-hello-name">Hello ${"{Name}"}</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Type System">
                    <a href="/langref#type-system-overview">Overview</a>
                    <a href="/langref#type-system-data-types">Data Types</a>
                    <a href="/langref#type-system-user-defined-types">User Defined Types</a>
                    <a href="/langref#type-system-function-and-closure-types">Function and Closure Types</a>
                    <a href="/langref#type-system-optional-types">Optional Types</a>
                    <a href="/langref#type-system-array-and-reference-types">Array and Reference Types</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Expressions">
                    <a href="/langref#expressions-literals">Literals</a>
                    <a href="/langref#expressions-variable-references">Variable References</a>
                    <a href="/langref#expressions-function-calls">Function Calls</a>
                    <a href="/langref#expressions-object-constructions">Object Constructions</a>
                    <a href="/langref#expressions-object-accesses">Object Accesses</a>
                    <a href="/langref#expressions-binary-and-unary-expressions">Binary and Unary Expressions</a>
                    <a href="/langref#expressions-range">Range Expressions</a>
                    <a href="/langref#protected-strings">Protected Strings</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Variables">
                    <a href="/langref#variables-declaration-syntax">Declaration Syntax</a>
                    <a href="/langref#variables-scoping">Scoping</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Functions">
                    <a href="/langref#functions-declaration-syntax">Declaration Syntax</a>
                    <a href="/langref#functions-function-overloading">Function Overloading</a>
                    <a href="/langref#functions-generic-functions">Generic Functions</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Closures">
                    <a href="/langref#closures-declaration-syntax">Declaration Syntax</a>
                    <a href="/langref#closures-captures">Captures</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Classes">
                    <a href="/langref#classes-attributes">Attributes</a>
                    <a href="/langref#classes-constructors-methods-and-operators">Constructors, Methods, and Operators</a>
                    <a href="/langref#classes-generics">Generics</a>
                    <a href="/langref#classes-inheritance">Inheritance</a>
                    <a href="/langref#classes-state">State</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Objects">
                    <a href="/langref#objects-instantiating-objects">Instantiating Objects</a>
                    <a href="/langref#objects-accessing-methods-and-attributes">Accessing Methods and Attributes</a>
                    <a href="/langref#objects-this-object">This Object</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Modules and Assets">
                    <a href="/langref#modules-module-declaration">Module Declaration</a>
                    <a href="/langref#modules-importing-modules">Importing Modules</a>
                    <a href="/langref#modules-accessing-symbols-in-a-module">Accessing Symbols in a Module</a>
                    <a href="/langref#modules-external-linkage">Linking With External Object and External Libraries</a>
                    <a href="/langref#modules-assets">Importing Assets</a>
                    <a href="/langref#modules-stylesheets">Importing Sass Stylesheets</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Visibilities">
                    <a href="/langref#visibilities-overview">Overview</a>
                    <a href="/langref#visibilities-variadic">Variadic</a>
                    <a href="/langref#visibilities-external">External</a>
                    <a href="/langref#visibilities-private">Private</a>
                    <a href="/langref#visibilities-public">Public</a>
                    <a href="/langref#visibilities-block-exit">Block Exit</a>
                    <a href="/langref#visibilities-no-track">No Track</a>
                    <a href="/langref#visibilities-state">State</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Control Flow">
                    <a href="/langref#control-flow-if-statements">If Statements</a>
                    <a href="/langref#control-flow-while-loops">While Loops</a>
                    <a href="/langref#control-flow-for-loops">For Loops</a>
                    <a href="/langref#control-flow-break-statements">Break Statements</a>
                    <a href="/langref#control-flow-return-statements">Return Statements</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="Platform Specifiers">
                    <a href="/langref#platform-specifiers-platform-statement">Platform Statement</a>
                    <a href="/langref#platform-specifiers-architecture-statement">Architecture Statement</a>
                </SidebarMenu>
                <SidebarMenu navstate={navState} title="CLI">
                    <a href="/langref#cli-build-command">Build Command</a>
                    <a href="/langref#cli-cross-platform-c-compiler">Cross-Platform C Compiler</a>
                </SidebarMenu>
            </Sidebar>
            <Content padding={70} leftMargin={0}>
                <Section header="Quick Start">
                    <SubSection id="quickstart-hello-world" header="Hello World">
                        <span>To get started, create a new file named <i>main.peko</i></span>
                        <span>The create the following code:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    fn OnStart() {
        console::println("Hello World!");
    }
\`\`\`
`)}}></code></pre>
                        <span>Now compile it using the following command:</span>
                        <pre><code className="language-bash">peko compile main.peko</code></pre>
                        <span>Now run the outputted executable, and see the output!</span>
                        <pre><code className="language-bash" dangerouslySetInnerHTML={{__html: marked(`
  \`\`\`bash
    ./a.out
    > Hello World!
  \`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection id="quickstart-hello-name" header={`Hello $\{Name}`}>
                        <span>This is an extension to hello world, showing off string templates and input in Pekoscript</span>
                        <span>To get started, create a new file named <i>main.peko</i></span>
                        <span>The create the following code:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
  \`\`\`typescript
    fn OnStart() {
        console::println("Hello \${console::input("Whats your name? ")}!");
    }
  \`\`\`
`)}}></code></pre>
                        <span>Now compile it using the following command:</span>
                        <pre><code>peko compile main.peko</code></pre>
                        <span>Now run the outputted executable, and see the output!</span>
                        <pre><code className="language-bash" dangerouslySetInnerHTML={{__html: marked(`
  \`\`\`bash
    ./a.out
    > Whats your name?
    | Joe
    > Hello Joe!
  \`\`\`
`)}}></code></pre>
                    </SubSection>
                </Section>

                <Section header="Type System">
                    <SubSection header="Overview" id="type-system-overview">
                        <span>Pekoscript has a very powerful type system, comprising of various types, such as generic types, class types, and function types</span>
                        <span>Pekoscript also has a very powerful type casting system, and it automatically converts values of datatypes to eachother (ex. string -{">"} int, int -{">"} string, int -{">"} double)</span>
                        <span>Pekoscript also has operator overloading for classes which allows for complex classes to be converted to data types</span>
                        <span>Pekoscript is able to convert a datatype to a class if the class is a wrapper of that datatype</span>
                        <span>Lastly, Pekoscript has strong error handling through its optionals, and the optional typecasting system is very robust</span>
                    </SubSection>
                    <SubSection header="Data Types" id="type-system-data-types">
                        <span>Data types in Pekoscript are the very basic built-in types</span>
                        <span>The data types Pekoscript offers are:</span>
                        <Table>
                            <TableItem symbol="int" definition="a 32 bit integer"/>
                            <TableItem symbol="int64" definition="a 64 bit integer"/>
                            <TableItem symbol="string" definition="an array of characters"/>
                            <TableItem symbol="float" definition="a 32 bit float"/>
                            <TableItem symbol="bool" definition="a 1 bit integer representing a boolean value"/>
                            <TableItem symbol="double" definition="a double"/>
                            <TableItem symbol="char" definition="an 8 bit integer representing a character"/>
                        </Table>
                    </SubSection>
                    <SubSection header="User Defined Types" id="type-system-user-defined-types">
                        <span>User defined types are any types that the user would create</span>
                        <span>Any of these are considered a user defined type:</span>
                        <ul>
                            <li>
                                <span>Class types</span>
                            </li>
                            <li>
                                <span>Generic class types</span>
                            </li>
                        </ul>
                    </SubSection>
                    <SubSection header="Function and Closure Types" id="type-system-function-and-closure-types">
                        <span>Function types and closure types are both types which represent the value of a function or closure</span>
                        <span>A user should not have to worry to much about using function types, but closure types will often be used to signify if a closure is being passed as an argument or variable value</span>
                        <span>The syntax of a function type is as follows:</span>
                        <pre><code>(return type)(argument list)</code></pre>
                        <span>The syntax of a closure type is as follows:</span>
                        <pre><code className="language-typescript">closure(argument list) ={">"} return type</code></pre>
                    </SubSection>
                    <SubSection header="Optional Types" id="type-system-optional-types">
                        <span>Optional types are the same as normal value types, except with a question mark at the end to signify it being an optional type</span>
                        <span>Any value that has an optional type must be unwrapped to be used</span>
                        <span>Any value can be converted automatically to its optional type correspondant</span>
                        <span>The syntax looks like this:</span>
                        <pre><code className="language-typescript">type?</code></pre>
                    </SubSection>
                    <SubSection header="Array and Reference Types" id="type-system-array-and-reference-types">
                        <span>Array types and reference types are functionally the same, but the different syntaxes can be helpful to differentiate whether a value is being referenced or is an array</span>
                        <span>The syntax for a reference type is:</span>
                        <pre><code>&type</code></pre>
                        <span>The syntax for an array type is:</span>
                        <pre><code className="language-typescript"><i>type</i>[]</code></pre>
                        <Note>An array type of a closure is not possible, as the array brackets will be grouped in with the return type of a closure. The solution is to refer to an array of closures as a reference</Note>
                        <span>This syntax can be stacked, so an array can be multiple dimensions and a reference can be referenced multiple times</span>
                    </SubSection>
                </Section>

                <Section header="Expressions">
                    <SubSection header="Literals" id="expressions-literals">
                        <span>Each data type in Pekoscript has a corresponding literal value</span>
                        <span>Some examples of these literals are</span>
                        <Table>
                            <TableItem symbol="int | int64" definition="42"/>
                            <TableItem symbol="float | double" definition="15.64"/>
                            <TableItem symbol="bool" definition="true | false"/>
                            <TableItem symbol="string" definition={"\"Hello World\""}/>
                            <TableItem symbol="char" definition="'a'"/>
                        </Table>
                    </SubSection>
                    <SubSection header="Variable References" id="expressions-variable-references">
                        <span>Variable references in Pekoscript are pretty straight forward</span>
                        <span>A variable is simply referenced through its name, which is a simple identifier</span>
                        <span>An identifier in Pekoscript is any string of letters, numbers, and underscores, and must start with an underscore or letter</span>
                        <span>This syntax can also be used to reference a non-generic function by value</span>
                    </SubSection>
                    <SubSection header="Function Calls" id="expressions-function-calls">
                        <span>Function calls in Pekoscript begin with the function value, which can be any expression (not just a variable reference)</span>
                        <span>The arguments to the function call are contained in parentheses and are comma seperated</span>
                        <span>An example function call would be:</span>
                        <pre><code className="language-typescript">print("Hello World")</code></pre>
                        <span>The arguments of a function call can also be passed by keyword, like this:</span>
                        <pre><code className="language-typescript">print(message="Hello World", end="\n")</code></pre>
                        <Note>If calling a function with keywords, all arguments must be provided through a keyword</Note>
                        <span>Pekoscript also has generic functions, so the generic type parameters need to passed through a list surrounded with a greater-than and lesser-than bracket after the function reference</span>
                        <pre><code className="language-typescript">print&lt;string&gt;("Hello World")</code></pre>
                        <Note>The function referenced to call a generic function must be an identifier</Note>
                    </SubSection>
                    <SubSection header="Object Constructions" id="expressions-object-constructions">
                        <span>Object constructions are how an instance of a class is created in Pekoscript</span>
                        <span>An object construction follows the same syntax rules as a function call, the only difference being that the function value be swapped with an identifier referring to a class</span>
                        <span>A generic object construction also follows the same syntax rules as a generic function call</span>
                    </SubSection>
                    <SubSection header="Object Accesses" id="expressions-object-accesses">
                        <span>Object accesses in Pekoscript are used to access the methods and attributes of an object</span>
                        <span>The syntax starts with the left hand side which can be any valid expression as long as it is a valid object</span>
                        <span>The right hand side will either be a method call or attribute access</span>
                        <span>Method calls have the same syntax rules as function calls, and attribute accesses have the same syntax rules as variable references</span>
                        <span>The left hand side and right hand side will be seperated by a dot</span>
                        <span>Here are some examples:</span>
                        <pre><code className="language-typescript">person.greet()</code></pre>
                        <pre><code className="language-typescript">person.name</code></pre>
                        <pre><code className="language-typescript">people[1].job()</code></pre>
                    </SubSection>
                    <SubSection header="Binary and Unary Expressions" id="expressions-binary-and-unary-expressions">
                        <span>Binary expressions in Pekoscript are any expression with a left hand side and right hand side seperated by an operator other than the dot</span>
                        <span>Binary expressions in Pekoscript can also use parentheses to signify precedence</span>
                        <span>Here are the available binary operators for Pekoscript:</span>
                        <Table>
                            <TableItem symbol="+" definition="addition" />
                            <TableItem symbol="-" definition="subtraction" />
                            <TableItem symbol="*" definition="multiplication" />
                            <TableItem symbol="/" definition="division" />
                            <TableItem symbol="%" definition="modulus" />
                            <TableItem symbol="^" definition="exponent" />
                            <TableItem symbol="&&" definition="boolean and" />
                            <TableItem symbol="||" definition="boolean or" />
                            <TableItem symbol="<" definition="less than" />
                            <TableItem symbol=">" definition="greater than" />
                            <TableItem symbol="<=" definition="less than or equal" />
                            <TableItem symbol=">=" definition="greater than or equal" />
                            <TableItem symbol="==" definition="equals" />
                            <TableItem symbol="!=" definition="not equals" />
                        </Table>
                        <span>Unary expressions in pekoscript are expressions with only one value and an operator</span>
                        <span>There are 3 unary operators in Pekoscript:</span>
                        <Table>
                            <TableItem symbol="!" definition="not" />
                            <TableItem symbol="&" definition="reference" />
                            <TableItem symbol="?" definition="optional unwrap" />
                        </Table>
                        <Note>The ? operator goes at the end of an optional value, not the front</Note>
                    </SubSection>
                    <SubSection header="Range Expressions" id="expressions-range">
                        <span>Range expressions in Pekoscript are a shorthand for the expression <pre><code>range(value, value)</code></pre></span>
                        <span>Range expressions take two values which are or are similiar to an integer</span>
                        <pre><code className="language-typescript">value1..value2</code></pre>
                    </SubSection>
                    <SubSection header="Protected Strings" id="protected-strings">
                        <span>protected strings in Pekoscript are normal strings prefixed by the # symbol.</span>
                        <span>the difference between a protected string and a normal string is that a protected string stores its value by allocating heap memory and storing each character individually. This hides the string value in the executable so hackers cannot easily uncover it.</span>
                        <span>This is useful for hiding credentials in an application, but shouln't be used for every string as it is slower and less memory efficient than a normal string</span>
                        <pre><code className="language-typescript">#"string"</code></pre>
                    </SubSection>
                </Section>
                <Section header="Variables">
                    <SubSection header="Declaration Syntax" id="variables-declaration-syntax">
                        <span>Variables are used to store values for further usage in Pekoscript</span>
                        <span>To declare a variable with type inference, use the walrus (:=) operator</span>
                        <pre><code>name := value</code></pre>
                        <span>To declare a variable with a type, use a colon to signify the type</span>
                        <pre><code className="language-typescript">name: type = value</code></pre>
                        <Note>Variables always require an initial value to be assigned. If you don't have one, assign it to the <i>Default</i> value</Note>
                    </SubSection>
                    <SubSection header="Scoping" id="variables-scoping">
                        <span>Variables can be scoped both globally and locally</span>
                        <span>Variables in global scope can be accessed from anywhere, unless they are marked as a private global in a seperate module</span>
                        <span>Variables in local scope can only be accessed in that scope and lower scopes, such as if statements and while loops</span>
                        <Note>Unlike some other compiled languages, there is no restriction to the values that are given to a global variable. This means any valid Pekoscript expression can be assigned to a global variable</Note>
                    </SubSection>
                </Section>
                <Section header="Functions">
                    <SubSection header="Declaration Syntax" id="functions-declaration-syntax">
                        <span>To declare a function in pekoscript, follow this syntax:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: "fn name(argument_name: argument_type, ...) => return_type { ... }"}}></code></pre>
                        <span>To declare a function with no return type, just emit the <i>={">"} return_type</i></span>
                        <span>To give the function variable arguments, use this syntax:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: "fn name(arguments, Args<type> => variable_arguments_name) => return_type { ... }"}}></code></pre>
                        <span>An example function with variable arguments:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
  \`\`\`typescript
    fn myprint(message: string, Args<string> => args) {
        print(message);

        for arg in args {
            print(arg)
        }
    }

    myprint("Hello ", "World.", "This is Pekoscript");
  \`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection header="Function Overloading" id="functions-function-overloading">
                        <span>Function overloading is very simple in Pekoscript</span>
                        <span>Simply declare more than one function with the same name but different argument types, and it has been overloaded</span>
                        <span>Now whenever calling that function, the variant with the argument types that matches the most will be called</span>
                        <Note>Peko doesn't implement function overloading for return types</Note>
                    </SubSection>
                    <SubSection header="Generic Functions" id="functions-generic-functions">
                        <span>Generic functions are declared the same as normal functions, but take in generic type parameters</span>
                        <span>This gives the power to write a function once but have it apply to multiple types</span>
                        <span>The syntax for a generic function is as follows:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: "fn name<type, ...>(argument_name: type) => return_type { ... }"}}></code></pre>
                        <span>An example of a generic function:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
  \`\`\`typescript
    fn myprint<T>(message: T) {
        print(message)
    }

    myprint<int>(12)
    myprint("Hello") // this works because Pekoscript infers the type T to be a string from the argument value
  \`\`\`
`)}}></code></pre>
                    </SubSection>
                </Section>
                <Section header="Closures">
                    <SubSection header="Declaration Syntax" id="closures-declaration-syntax">
                        <span>Closures are a really powerful way to create an anonymous function in pekoscript, as variables from the scope in which the closure was created can be used in the closures scope</span>
                        <span>To declare a closure, the syntax is:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: "closure[captured_variable, ...](argument_name: type) => return_type { ... }"}}></code></pre>
                        <span>The create a closure that does not return, just emit the <i>={">"} return_type</i></span>
                        <span>An example of this would be:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
  \`\`\`typescript
    name := "Joe Smith"
    createMessage := closure[name](message: string) => string {
        name = \`$\{name}!\`
        return \`$\{message}, $\{name}\`;
    }

    console::println(name); // prints Joe Smith
    console::println(createMessage("Hello")); // prints Hello, Joe Smith!
    console::println(createMessage("Hello")); // prints Joe Smith!
  \`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection header="Captures" id="closures-captures">
                        <span>A closures capture list is a list of variables the closure brings from it declaration scope into its own scope.</span>
                        <span>Any caputured variable can be changed and referenced, even when its declaration scope exits</span>
                    </SubSection>
                </Section>
                <Section header="Classes">
                    <SubSection header="Attributes" id="classes-attributes">
                        <span>Likewise to other object oriented programming languages, Pekoscript classes have attributes which are named value stored in an object</span>
                        <span>Their declaration includes a name and type, as well as the option to provide a visibility</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    class name {
        attribute_name: type;
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection header="Constructors, Methods, and Operators" id="classes-constructors-methods-and-operators">
                        <span>All constructors, methods, and operators in Pekoscript are automatically provided with the <i>this</i> object, which is a reference to the object this method is being called on</span>
                        <span>Constructors are what is called when an object is instantiated</span>
                        <span>The constructor is signified with the <i>constructor</i> keyword</span>
                        <span>Constructors can also have an optional call to the super constructor if a class is inherited</span>
                        <span>The constructor syntax is similiar to a function declaration, with the only difference being that the return type is instead used to specify a super constructor call</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    class name {
        // A normal constructor call
        constructor(arguments) {
            ...
        }

        // A constructor with a super call
        constructor(arguments) => super(super_constructor_arguments) {
            ...
        }
    }
\`\`\`
`)}}></code></pre>
                        <Note>Constructors cannot have variable arguments</Note>
                        <span>Methods in pekoscript have the same declaration syntax and behavior as functions</span>
                        <span>Class operators offer a way to overload normal operators to have different behavior when used with this class</span>
                        <span>Operators in pekoscript have the same argument and return type syntax as functions in pekoscript, but the naming syntax is a little different</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    class name {
        [operator operator_name](arguments) => return_type{
            [body]
        }
    }
\`\`\`
`)}}></code></pre>
                        <span>All available operator overload are:</span>
                        <Table>
                            <TableItem symbol="[operator +]" definition="overload for the + operator" />
                            <TableItem symbol="[operator -]" definition="overload for the - operator" />
                            <TableItem symbol="[operator *]" definition="overload for the * operator" />
                            <TableItem symbol="[operator /]" definition="overload for the / operator" />
                            <TableItem symbol="[operator %]" definition="overload for the % operator" />
                            <TableItem symbol="[operator ^]" definition="overload for the ^ operator" />
                            <TableItem symbol="[operator ?]" definition="overload for the unwrap operator (?)" />
                            <TableItem symbol="[operator to_string]" definition="overload for converting the class to a string" />
                            <TableItem symbol="[operator to_int]" definition="overload for converting the class to a int" />
                            <TableItem symbol="[operator to_int64]" definition="overload for converting the class to a int64" />
                            <TableItem symbol="[operator to_float]" definition="overload for converting the class to a float" />
                            <TableItem symbol="[operator to_double]" definition="overload for converting the class to a double" />
                            <TableItem symbol="[operator to_char]" definition="overload for converting the class to a char" />
                            <TableItem symbol="[operator to_bool]" definition="overload for converting the class to a bool" />
                            <TableItem symbol="[operator iter]" definition="overload for getting the iterator from an object, called in for loops" />
                        </Table>
                    </SubSection>
                    <SubSection header="Generics" id="classes-generics">
                        <span>Generics in Pekoscript offer a way to make classes more versatile by allowing a class to apply to multiple types</span>
                        <span>Generics have the same declaration syntax as a normal class, the only difference being that a list of type parameters is provided after the class name</span>
                        <span>The syntax for the generic types is as follows:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    class name<type parameters> {
        class body
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection header="Inheritance" id="classes-inheritance">
                        <span>Inheritance in Pekoscript works similiar to inheritance in other object oriented programming languages, with the only difference being multiple inheritance is not supported (done as a design decision)</span>
                        <span>The syntax to signify inheritance is as follows:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
    \`\`\`typescript
        class name from base {
            class body
        }
    \`\`\`
    `)}}></code></pre>
                    </SubSection>
                    <SubSection header="State" id="classes-state">
                        <span>Since Peko is a language which is used in ui development, classes have built in state.</span>
                        <span>To create a state variable in Peko, an attribute can be marked with the [state] modifier like so:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    class name {
        [state] state_name: type;
    }
\`\`\`
`)}}></code></pre>
                        <span>After marking an attribute as state, whenever this attribute is changed, the owner object is notified through the <i>onStateChanged</i> function</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    class name {
        fn onStateChanged(state_name: string) {
            ...
        }
    }
\`\`\`
`)}}></code></pre>
                        <span>the <i>state_name</i> argument to this function will hold the name of the state attribute that was changed</span>
                    </SubSection>
                </Section>
                <Section header="Objects">
                    <SubSection header="Instantiating Objects" id="objects-instantiating-objects">
                        <span>Object constructions are how an instance of a class is created in Pekoscript</span>
                        <span>An object construction follows the same syntax rules as a function call, the only difference being that the function value be swapped with an identifier referring to a class</span>
                        <span>A generic object construction also follows the same syntax rules as a generic function call</span>
                    </SubSection>
                    <SubSection header="Accessing Methods and Attributes" id="objects-accessing-methods-and-attributes">
                        <span>Object accesses in Pekoscript are used to access the methods and attributes of an object</span>
                        <span>The syntax starts with the left hand side which can be any valid expression as long as it is a valid object</span>
                        <span>The right hand side will either be a method call or attribute access</span>
                        <span>Method calls have the same syntax rules as function calls, and attribute accesses have the same syntax rules as variable references</span>
                        <span>The left hand side and right hand side will be seperated by a dot</span>
                        <span>Here are some examples:</span>
                        <pre><code className="language-typescript">person.greet()</code></pre>
                        <pre><code className="language-typescript">person.name</code></pre>
                        <pre><code className="language-typescript">people[1].job()</code></pre>
                    </SubSection>
                    <SubSection header="This Object" id="objects-this-object">
                        <span>In order to allow the reference to the object a method is being called on, each constructor, method, and operator overload in a class have access to the this object</span>
                        <span>Using the this object, other methods and attributes can be accessed</span>
                        <span>Other than that, the this object behaves as any other object in Pekoscript</span>
                        <span>The this object is not needed to reference other class methods and attributes, as Pekoscript automatically detects whether or not a function call can be referenced from the current class</span>
                        <span>This means both of the following expressions are equivalent</span>
                        <span class="syntax-options">
                            <pre><code className="language-typescript">this.method()</code></pre>
                            <span class="option-seperator">and</span>
                            <pre><code className="language-typescript">method()</code></pre>
                        </span>
                    </SubSection>
                </Section>
                <Section header="Modules and Assets">
                    <SubSection header="Module Declaration" id="modules-module-declaration">
                        <span>Module declarations are very simple in Pekoscript, all that is needed is the module name and the body of the module contained in curly braces</span>
                        <span>A module body can contain any global level expressions, including but not limited to function and class declarations, variable declarations, and even other module declarations</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    module name {
        ...
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection header="Importing Modules" id="modules-importing-modules">
                        <span>The pekoscript module import syntax has a few variations</span>
                        <span>To simply import a named module, use the syntax:</span>
                        <span class="syntax-options">
                            <pre><code className="language-typescript">import module_name</code></pre>
                            <span class="option-seperator">or</span>
                            <pre><code className="language-typescript">import module_name as name</code></pre>
                        </span>
                        <span>The second option utilizing the as just allows importing the module with an alias name</span>
                        <span>Module names will always be a valid identifier, unless importing from a folder, then the following syntax is used</span>
                        <pre><code className="language-typescript">import folder_name::module_name</code></pre>
                        <span>To import a top-level function, module, class, or variable from a module, use this syntax</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: "import {symbol_name, symbol_name, ...} from module_name"}}></code></pre>
                        <span>To import every top-level expression from a module into the current scope, just use</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: "import { * } from module_name"}}></code></pre>
                    </SubSection>
                    <SubSection header="Accessing Symbols in a Module" id="modules-accessing-symbols-in-a-module">
                        <span>Accessing symbols from a module in Pekoscript has very similiar syntax to Rust or C++, where the module name is seperated from the symbol by a double colon ::</span>
                        <pre><code className="language-typescript">module_name::symbol_name</code></pre>
                    </SubSection>
                    <SubSection header="Linking With External Object and External Libraries" id="modules-external-linkage">
                        <span>To link with external libraries and objects Pekoscript, there is a dedicate link statement similiar to the import statement</span>
                        <span>It requires the name of the object or library and what type of file it is being linked as</span>
                        <pre><code className="language-typescript">link [archive or object name] as [object|lib|archive]</code></pre>
                        <Note>omit the file type from the object name, as thats inferred from the link type</Note>
                        <span>Here is what each linker option correlates to:</span>
                        <Table>
                            <TableItem symbol="object" definition=".o files" />
                            <TableItem symbol="lib" definition=".lib files, or a windows archive" />
                            <TableItem symbol="archive" definition=".a files" />
                        </Table>
                        <span>Pekoscript does not support dynamic linking</span>
                    </SubSection>
                    <SubSection header="Importing Assets" id="modules-assets">
                        <span>When building a project, Peko will detect assets, and allow for their importation</span>
                        <span>To import an asset, first place it in your projects <i>Asset</i>, making sure the files basename is a valid pekoscript identifier</span>
                        <span>Then, import that asset using the following syntax:</span>
                        <pre><code className="language-typescript">asset asset_file_base_name</code></pre>
                    </SubSection>
                    <SubSection header="Importing Sass Stylesheets" id="modules-stylesheets">
                        <span>The Peko compiler can parse Sass and import a Sass file as a string.</span>
                        <span>To do include a Sass file, it is very simple:</span>
                        <pre><code className="language-typescript">style style_sheet_name</code></pre>
                        <span>Similiar to assets, the stylesheet file must have a basename that is a valid Pekoscript identifier</span>
                        <span>To reference a filesheet in a folder, use the same syntax as importing a module from a different file</span>
                        <span>After using this import, the parsed sass will be stored in style_sheet_name to be used the same as a variable.</span>
                        <span>For example:</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    style MainStyling;

    fn OnStart() {
        console::println(MainStyling); // prints the parsed sass stored in MainStyling
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                </Section>
                <Section header="Visibilities">
                    <SubSection header="Overview" id="visibilities-overview">
                        <span>Visbilities are used in pekoscript to give extra insight to the compiler on certain attributes of top-level expressions such as functions, global variables, classes, as well as lower-level things like class attributes and class constructors, methods, and operator overloads</span>
                        <span>Visbilities are indicated with the following syntax, and there can be multiple keywords in one visibility as long as the keywords are seperated by spaces</span>
                        <pre><code className="language-typescript">[visiblity]</code></pre>
                    </SubSection>
                    <SubSection header="Variadic" id="visibilities-variadic">
                        <span>This is mainly intended for linkage with C functions that utilize C-style variadic arguments such as printf</span>
                        <span>Using this visibility is equivalent to adding ... to the end of an argument list in C</span>
                        <span>To use variable arguments in Pekoscript, utilize the built-in variable arguments for functions</span>
                        <span>The keyword for this visibility is <i>variadic</i></span>
                    </SubSection>
                    <SubSection header="External" id="visibilities-external">
                        <span>This is used when linking with external symbols, and when a symbol is marked with this no name mangling will be applied</span>
                        <span>Any symbol marked by this visiblity will be stored in the <i>extern</i> module</span>
                        <span>The keyword for this visibility is <i>external</i></span>
                    </SubSection>
                    <SubSection header="Private" id="visibilities-private">
                        <span>When this visibility is used on global variables, functions, and classes these private symobls will only be accessible from their module in scope and other modules cannot access them</span>
                        <span>When this visibility is used on class methods and attributes, the methods or attributes will only be accessible through the this object, or in other words the class which those symbols belong to</span>
                        <span>The keyword for this visibility is <i>private</i></span>
                    </SubSection>
                    <SubSection header="Public" id="visibilities-public">
                        <Note>This visibility is redundant and has no real usage, unless used for clarity in code</Note>
                        <span>This visibility is the direct opposite of the public visibility, and any symbol marked with this will be accessible from anywhere as long as the symbol is in scope</span>
                        <span>The keyword for this visibility is <i>public</i></span>
                    </SubSection>
                    <SubSection header="Block Exit" id="visibilities-block-exit">
                        <span>This visibility marks if a function will guarantee an exit from the current block/function</span>
                        <span>This helps the compiler mark the block as finished and not arise an error</span>
                        <span>The keyword for this visibility is <i>blockexit</i></span>
                        <Note>This really should not be used, unless you know what your doing</Note>
                    </SubSection>
                    <SubSection header="No Track" id="visibilities-no-track">
                        <span>This is a more internal visibility, and it tells pekoscript to not track the line number and file name in the scope of this function</span>
                        <span>This is because Pekoscript tracks the line number and file name of each function for use in diagnostics, which means unless this visibility is used the current scope will have each function call tracke</span>
                        <span>The keyword for this visibility is <i>notrack</i></span>
                    </SubSection>
                    <SubSection header="State" id="visibilities-state">
                        <span>This is how state is declared on a class attribute, referenced <a href="#classes-state">here</a></span>
                        <span>The keyword for this visibility is <i>state</i></span>
                    </SubSection>
                </Section>
                <Section header="Control Flow">
                    <SubSection header="If Statements" id="control-flow-if-statements">
                        <span>If statements in Pekoscript behave the same as every other language</span>
                        <span>The if statement starts with the <i>if</i> keyword, following will be the condition</span>
                        <span>If else blocks are marked by the <i>else</i> keyword followed by the <i>if</i> keyword, and the conditon will come after</span>
                        <span>Else blocks are marked by the <i>else</i> keyword</span>
                        <Note>Parentheses around the condition are optional</Note>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    if condition {
        ...
    } else if condition {
        ...
    } else {
        ...
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection header="While Loops" id="control-flow-while-loops">
                        <span>While loops are the most simple loop in Pekoscript, and simply take a condition and loop body</span>
                        <span>The while loop continues to loop until its condition is false</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    while condition {
        ...
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection header="For Loops" id="control-flow-for-loops">
                        <span>For loops are used for looping through an iterator in Pekoscript</span>
                        <span>For loops take a name to store each item of the iteration in and the iteratable</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    for item_name in iterable {
        ...
    }
\`\`\`
`)}}></code></pre>
                    </SubSection>
                    <SubSection header="Break Statements" id="control-flow-break-statements">
                        <span>Break statements are used in a loop to return back to the upper scope, terminating the loop</span>
                        <span>The break statement simply is a single keyword: <i>break</i></span>
                    </SubSection>
                    <SubSection header="Return Statements" id="control-flow-return-statements">
                        <span>Return statements are used to signify the return of a function</span>
                        <span>A return statement either returns a value or nothing if the function is void</span>
                        <span class="syntax-options">
                            <pre><code className="language-typescript">return</code></pre>
                            <span class="option-seperator">or</span>
                            <pre><code className="language-typescript">return expression</code></pre>
                        </span>
                    </SubSection>
                </Section>
                <Section header="Platform Specifiers">
                    <SubSection header="Platform Statement" id="platform-specifiers-platform-statement">
                        <span>The platform statement allows certain code to be generated only if the target operating system matches</span>
                        <span>A platform statement can specify a single operating system or multiple. If multiple operating systems are specified, each one must be seperated by a |</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    platform system1|system2|... {
        ...
    }
\`\`\`
`)}}></code></pre>
                        <span>The options for operating system are:</span>
                        <ul>
                            <li>android</li>
                            <li>ios</li>
                            <li>linux</li>
                            <li>macos</li>
                            <li>windows</li>
                        </ul>
                    </SubSection>
                    <SubSection header="Architecture Statement" id="platform-specifiers-architecture-statement">
                        <span>The architecture statement performs the same task as the platform statement, except it applys to the target architecutre instead of the target operating system</span>
                        <pre><code className="language-typescript" dangerouslySetInnerHTML={{__html: marked(`
\`\`\`typescript
    arch arch1|arch2|... {
        ...
    }
\`\`\`
`)}}></code></pre>
                        <span>The options for architectures are:</span>
                        <ul>
                            <li>arm</li>
                            <li>x86_64</li>
                        </ul>
                    </SubSection>
                </Section>
                <Section header="CLI">
                    <SubSection header="Build Command" id="cli-build-command">
                        <span>This command is used to compile any Pekoscript file</span>
                        <span>The syntax is:</span>
                        <pre><code className="language-bash">peko compile [arguments] [peko file name]</code></pre>
                        <span>The available flags for this command are:</span>
                        <Table>
                            <TableItem symbol="--os=[operating system]" definition="Specify the target operating system, [operating system] can be any valid Pekoscript OS target, specified by the Platform Specifier docs" />
                            <TableItem symbol="--arch=[architecture]" definition="Specify the target architecture, [architecture] can be any valid Pekoscript architecture target, specified by the Platform Specifier docs" />
                        </Table>
                    </SubSection>
                    <SubSection header="Cross-Platform C Compiler" id="cli-cross-platform-c-compiler">
                        <span>This command is used to compile any C source to object code for linking with Pekoscript</span>
                        <span>The Pekoscript compiler utilizes clang and pairs it with the Pekoscript toolchains to achieve this cross-compilation</span>
                        <span>The syntax is:</span>
                        <pre><code className="language-bash">peko clang [arguments] [c source file]</code></pre>
                        <span>The available flags for this command are:</span>
                        <Table>
                            <TableItem symbol="--os=[operating system]" definition="Specify the target operating system, [operating system] can be any valid Pekoscript OS target, specified by the Platform Specifier docs" />
                            <TableItem symbol="--arch=[architecture]" definition="Specify the target architecture, [architecture] can be any valid Pekoscript architecture target, specified by the Platform Specifier docs" />
                            <TableItem symbol="--args=[args]" definition="Pass any other argument to the clange compiler, [args] should be a string" />
                        </Table>
                    </SubSection>
                </Section>
            </Content>
        </>
    )
}