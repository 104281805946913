import { useState } from "react";
import { Helmet } from "react-helmet";

import { SidebarComponents } from "../../Components/Bars/Sidebar/component"
import { Content } from "../../Components/Containers/Content/component";
import { ModuleDisplay } from "../../Components/Containers/ModuleDisplay/component";
import { useLocation } from "react-router-dom";

let { SidebarItem, SidebarMenu, Sidebar } = SidebarComponents;
let Libraries = require('../../libraries.json');

export default function LibraryDocs() {
    let navState = useState(true);
    const { pathname } = useLocation();

    let modules = pathname.substring(9).split("/");
    
    for (let i = modules.length - 1; i >= 0; i--) {
        if (modules[i] === "") {
            modules.splice(i, 1);
        }
    }

    let libraries_list = Libraries["libraries"]

    let library = libraries_list[0];
    for(let i = 0; i < libraries_list.length; i++) {
        if(libraries_list[i].name.toLowerCase() === modules[0].toLowerCase()) {
            library = libraries_list[i];
        }
    }

    let library_name = library.name;
    let parent_module = {
        path: "/" + pathname.split("/")[1] + "/" + library_name,
        name: library_name,
    }

    for(let i = 1; i < modules.length; i++) {
        let found_current_module = false;

        for(let j = 0; j < library.modules.length; j++) {
            if(library.modules[j].name === modules[i]) {
                if(i > 1) {
                    parent_module.name += library_name;
                    parent_module.path += "/" + library_name;
                }
                
                library = library.modules[j];
                library_name += "::"+library.name;
                found_current_module = true;
                
                break;
            }
        }

        if(!found_current_module) {
            return (<></>);
        }
    }

    return (
        <>
            <Helmet>
                <title>Peko Docs</title>
                <meta property="og:title" content="Peko Docs" />
                <meta property="og:description" content="Learn about everything Peko, from the language to modules" />
                <meta name="description" content="Learn about everything Peko, from the language to modules" />
                <meta property="og:url" content="https://docs.pekoui.com" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Sidebar navstate={navState} title="Peko Docs" footer>
                <SidebarItem navstate={navState} label="Language Reference" to="/langref"></SidebarItem>
                <SidebarItem navstate={navState} label="PekoUI | UI Dev" to="/pekoui"></SidebarItem>
                <SidebarMenu navstate={navState} title="Built-in Libraries">
                    <a href="/library/std">Standard Library</a>
                    <a href="/library/runtime">Runtime Library</a>
                    <a href="/library/console">Console Library</a>
                    <a href="/library/json">JSON Library</a>
                    <a href="/library/lexer">Lexer Library</a>
                    <a href="/library/threads">Threads Library</a>
                    <a href="/library/sockets">Sockets Library</a>
                    <a href="/library/mysql">MySQL Library</a>
                    <a href="/library/pekodb">PekoDB Library</a>
                    <a href="/library/crypto">Crypto Library</a>
                    <a href="/library/pekox">PekoXML Library</a>
                </SidebarMenu>
            </Sidebar>
            <Content leftMargin={41}>
                <ModuleDisplay parent_module={(modules.length > 1) ? parent_module : undefined} title={library.title} name={library_name} json={library} />
            </Content>
        </>
    )
}