import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Page(props) {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        if(hash === '') {
            window.scrollTo(0, 0);
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 100);
        }
    }, [pathname, hash, key]);
    
    return (
        <AnimatePresence>
            <motion.div key={props.keyid} initial={{opacity: 0}} animate={{opacity: 100}} transition={{duration: 0.5}}>
                {props.page()}
            </motion.div>
        </AnimatePresence>
    );
}