import './global.css';

import "highlight.js/styles/github.css";
import hljs from "highlight.js";

// React imports
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Import components
import Layout from './Layout/layout';
import Page from './Pages/Page';

// Import pages
import Index from './Pages/Index/page';
import LagnuageReference from './Pages/LanguageReference/page';
import LibraryDocs from './Pages/LibraryDocs/page';
import PekoUIDocs from './Pages/PekoUIDocs/page';

export default function App() {
  useEffect(() => {
    hljs.highlightAll();
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Layout}>
          <Route index element={<Page keyid={"/"} page={Index} />} />
          <Route path="/library/*" element={<Page keyid={"/library"} page={LibraryDocs} />} />
          <Route path="/langref" element={<Page keyid={"/langref"} page={LagnuageReference} />} />
          <Route path="/pekoui" element={<Page keyid={"/pekoui"} page={PekoUIDocs} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}